<template>
	<v-card flat class="mt-5" v-if="section">
		<v-card flat class="mt-4 mb-4" color="lightest">
			<v-card-title>
				{{ $t("staff_survey.title") }}
			</v-card-title>
			<v-card-text v-html="section.introduction"> </v-card-text>
		</v-card>
		<template v-if="status == 'not_started'">
			<v-card-text>
				<div class="mb-6">
					<toggle-category
						v-model="job_function"
						id="jobFunction"
						storeModule="jobFunctions"
						multiple
						:tooltip="$t('staff_survey.job_function_tooltip')"
						:title="$t('staff_survey.job_function_text')"
					></toggle-category>
				</div>
				<div class="mb-6">
					<toggle-category
						v-model="service"
						id="service"
						storeModule="services"
						multiple
						:tooltip="$t('staff_survey.service_tooltip')"
						:title="$t('staff_survey.service_text')"
					></toggle-category>
				</div>
				<questions
					:ids="visibleQuestions"
					v-model="data"
					staff-survey
				></questions>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="save" color="primary">{{ $t("buttons.save") }}</v-btn>
			</v-card-actions>
		</template>
		<template v-else>
			<v-card-text>
				<p>{{ $t("staff_survey.thanks") }}</p>
			</v-card-text>
		</template>
	</v-card>
</template>

<script>
	import ToggleCategory from "@c/ui/ToggleCategory.vue";
	import Questions from "@c/survey/render/Questions.vue";
	export default {
		name: "StaffSurvey",
		props: {
			org: {type: String}
		},
		data: () => {
			return {
				survey_id: "F4ayWSwHXBl8X1QXbXK4",
				job_function: "",
				service: "",
				data: {},
				status: "not_started"
			};
		},
		computed: {
			staffSurveyQuestions(){
				return this.$store.state.staffSurveyQuestions.data;
			},
			questions() {
				return this.$store.state.questions.data;
			},
			section() {
				return this.$store.state.sections.data[this.survey_id];
			},
			visibleQuestions() {
				const self = this;
				if (!self.section) {
					return [];
				}
				return self.section.questions
					.filter((q) => self.questions[q])
					.filter((q) => !self.questions[q].custom || self.isCustomQuestionForOrganisation(q))
					.filter((q) => {
						let match =
							self.testCategoryRules(q) &&
							self.mwsurveyutils.isVisible(q, {
								data: self.data,
								section: self.section.id
							});
						return match;
					});
			},
			completionRate() {
				var answered = Object.values(this.data).filter((a) => {
					return typeof a !== "undefined" && a.length > 0;
				});
				var data = this.visibleQuestions;
				return Math.floor((answered.length / data.length) * 100);
			}, 
			tags() {
				let tags = this.$route.query.tags;
				if( tags ){
					return tags.split(",")
				}
				else{
					return null;
				}
			}
		},
		components: {
			Questions,
			ToggleCategory
		},
		created() {
			const self = this;
			self.$store.dispatch("sections/fetchById", self.survey_id);
			self.$store.dispatch("jobFunctions/openDBChannel");
			self.$store.dispatch("services/openDBChannel");
			self.$store.dispatch("staffSurveyQuestions/openDBChannel");
		},
		methods: {
			isCustomQuestionForOrganisation(id){
				let questions = Object.values(this.staffSurveyQuestions);
				let question = questions.find( item => item.question == id );
				return question.organisations.includes(this.org);
			},
			testCategoryRules(id) {
				const self = this;
				let question = self.questions[id];

				if (self.mwsurveyutils.questionHasConditions(question)) {
					let rules = question.rules.filter((r) =>
						["jobFunction", "service"].includes(r.category)
					);

					if (rules.length) {
						let match = rules.find((rule) => {
							return self.mwutils.testRule(rule, {
								jobFunction: self.job_function,
								service: self.service
							});
						});
						if (match) {
							return true;
						} else {
							return false;
						}
					} else {
						return true;
					}
				} else {
					return true;
				}
			},
			save() {
				var self = this;
				self.$store
					.dispatch("staffSurveyResponses/insert", {
						data: self.data,
						organisation: self.org,
						section: self.survey_id,
						jobFunction: self.job_function,
						service: self.service,
						status: "submitted",
						tags: self.tags
					})
					.then(() => {
						self.status = "submitted";
					});
			}
		}
	};
</script>
